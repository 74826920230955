import classNames from "classnames";
// import Chart from "chart.js/auto"; // import needed to render charts
import { AppContentHeader } from "components";
import { AppColoredMultiSelect } from "components/AppSelect";
import { AppButtonDatepicker } from "components/FormFields";
import { AppLoader, AppOverlayLoader } from "components/Loaders";
import { ISO8601_DATE_FORMAT } from "globals/constants";
import { getMillisecondsForMinutes } from "globals/helpers/generalHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useWindowSize } from "hooks/general/reactHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo, isNil } from "lodash-es";
import { KindergartenClientReportPlanningRequest } from "models";
import { DatePickerType } from "models/datepicker";
import { CustomColorItemOption } from "models/general";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
    CurrentBusinessService,
    getCurrentBusinessServiceKey,
} from "services/business";
import {
    getKindergartenPlanningReportsServiceKey,
    KindergartenPlanningReportsService,
} from "services/kindergarten";
import styles from "./KindergartenAgeGroupPlanning.module.scss";
import { KindergartenAgeGroupReportResponse } from "models/kindergartenAgeGroupReport";
import AgeGroupMultiSeriesPieChart from "./partials/AgeGroupMultiSeriesPieChart";
import {
    Chart as ChartJS,
    ArcElement,
    BarElement,
    Tooltip,
    Legend,
    Title,
    CategoryScale,
    LinearScale,
} from "chart.js";
import { AgeGroupRegistrationsBarChart } from "screens/kindergarten/ageGroupPlaning/partials";
ChartJS.register(
    ArcElement,
    BarElement,
    Tooltip,
    Legend,
    Title,
    CategoryScale,
    LinearScale
);

interface AgeGroupPlanningState {
    request: KindergartenClientReportPlanningRequest;
    groupOptions: CustomColorItemOption[];
    data?: KindergartenAgeGroupReportResponse;
}

export const KindergartenAgeGroupPlanning = () => {
    const { linkProvider } = useRouting();
    const { encodedId: encodedBusinessId } = useSessionBusiness();
    const { width } = useWindowSize();
    const mountToBody = useMemo(() => {
        return width < 900;
    }, [width]);
    const { t } = useTranslation();

    const emptyOption = useMemo(() => {
        return {
            label: t("common.pleaseSelect"),
            value: "",
        } as CustomColorItemOption;
    }, [t]);
    const [state, setState] = useState<AgeGroupPlanningState>({
        request: {
            StartMonth: moment().startOf("month"),
        },
        groupOptions: [emptyOption],
        data: undefined,
    });
    const occupancyService = new KindergartenPlanningReportsService(
        linkProvider.kindergarten.api.kindergartenReports
    );
    const currentBusinessService = new CurrentBusinessService(
        linkProvider.business.api.currentBusiness()
    );

    const { isLoading: groupsLoading, data: groupsResponse } = useQuery(
        getCurrentBusinessServiceKey("getBusinessGroups", {
            encodedBusinessId: encodedBusinessId,
            date: state.request.StartMonth.toString(),
        }),
        async () =>
            await currentBusinessService.getBusinessGroups(
                state.request.StartMonth,
                true
            )
    );

    const {
        isLoading: loading,
        isFetching: fetching,
        data: response,
        error,
    } = useQuery(
        getKindergartenPlanningReportsServiceKey("getAgeGroupReport", {
            month: state.request.StartMonth.toISOString(),
            groupIds: defaultTo(state.request.GroupIds, []),
        }),
        async () => await occupancyService.getAgeGroupReport(state.request),
        {
            cacheTime: getMillisecondsForMinutes(15),
        }
    );

    const groupOptions = state.groupOptions;
    useEffect(() => {
        if (
            !groupsLoading &&
            groupsResponse &&
            groupsResponse.Data &&
            groupsResponse.Data.length > 0
        ) {
            const options = groupsResponse.Data.map((x) => {
                return {
                    label: x.Text,
                    value: parseInt(x.Value as string),
                } as CustomColorItemOption;
            });
            const availableGroups = options.map((x) => x.value);
            setState({
                ...state,
                request: {
                    ...state.request,
                    GroupIds: state.request.GroupIds
                        ? state.request.GroupIds.filter((x) =>
                              availableGroups.includes(x)
                          )
                        : state.request.GroupIds,
                },
                groupOptions: options,
            });
        }
    }, [groupsResponse, groupsLoading]);

    useEffect(() => {
        if (!loading && response && response.Data) {
            setState((s) => ({
                ...s,
                data: {
                    ...response.Data,
                    Data: response.Data.Data.map((t) => ({
                        ...t,
                        Month: moment(t.Month, ISO8601_DATE_FORMAT),
                    })),
                },
            }));
        } else if (!loading && error) {
            setState((s) => ({
                ...s,
                data: { DesiredCapacity: 0, Data: [] },
            }));
        }
    }, [loading, response]);

    const datePicker = useMemo(
        () => (
            <AppButtonDatepicker
                value={state.request.StartMonth}
                readonly={loading}
                className={styles.picker}
                onChange={(val) =>
                    setState({
                        ...state,
                        request: {
                            ...state.request,
                            StartMonth: defaultTo(val, moment()),
                        },
                    })
                }
                pickerType={DatePickerType.MONTH_YEAR}
            />
        ),
        [state, loading]
    );

    return (
        <div className={styles.root}>
            <AppContentHeader
                // addScroll={false}
                classNameForIcon="pe-7s-graph"
                className={styles.root}
                title={t("ageGroupPlanning.title")}
            >
                <div className={classNames(styles.headerContent)}>
                    <div style={{ width: "350px" }}>
                        <AppColoredMultiSelect
                            value={groupOptions.filter((x) =>
                                defaultTo(state.request.GroupIds, []).includes(
                                    x.value as number
                                )
                            )}
                            onChange={(values) => {
                                setState({
                                    ...state,
                                    request: {
                                        ...state.request,
                                        GroupIds:
                                            groupOptions.length == values.length
                                                ? undefined // in case all are selected send undefined instead of selected Ids
                                                : values.map(
                                                      (x) => x.value as number
                                                  ),
                                    },
                                });
                            }}
                            menuPortalTarget={
                                mountToBody ? document.body : undefined
                            }
                            loading={loading}
                            data={groupOptions}
                            isReadonly={false}
                        />
                    </div>
                    {datePicker}
                </div>
            </AppContentHeader>
            {isNil(state.data) ? (
                <AppLoader />
            ) : (
                <>
                    {fetching ? (
                        <AppOverlayLoader />
                    ) : (
                        <div className={styles.chartsContainer}>
                            {state.data ? (
                                state.data.Data.map((d) => {
                                    return (
                                        <div
                                            key={d.Month.toISOString()}
                                            className={styles.chartSection}
                                        >
                                            <AgeGroupMultiSeriesPieChart
                                                data={d}
                                                selectedYear={state.request.StartMonth.year()}
                                                totalKids={
                                                    state.data
                                                        ?.DesiredCapacity as number
                                                }
                                            />
                                            <AgeGroupRegistrationsBarChart
                                                data={d}
                                            />
                                        </div>
                                    );
                                })
                            ) : (
                                <h1>{t("common.noDataFound")}</h1>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default KindergartenAgeGroupPlanning;
