import { AppIconButton } from "components/Buttons";
import { ImageAssets } from "globals/images";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { debounce, defaultTo, isNil } from "lodash-es";
import {
    ClientContractsTableColumnKeys,
    ClientContractType,
    CompensationTemplateType,
    KindergartenClientContractCopyRequest,
    KindergartenClientContractDetail,
    KindergartenClientContractType,
    KindergartenClientContractUpdateRequest,
} from "models";
import { DATEPICKER_MONTH_FORMAT } from "models/datepicker";
import { Moment } from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import KindergartenClientContractSingleValueCell from "./KindergartenClientContractSingleValueCell";
import KindergartenClientContractDropdownCell from "./KindergartenClientContractDropdownCell";
import styles from "./KindergartenClientContractRow.module.scss";
import { AppInputField, AppNumberInput } from "components/FormFields";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import KindergartenClientContractNumberInputCell from "screens/kindergarten/clients/tabs/contracts/partials/KindergartenClientContractNumberInputCell";
import { Optional } from "models/general";

export interface KindergartenClientContractRowProps {
    value: KindergartenClientContractDetail;
    canEdit: boolean;
    // keyString: string;
    columnsMapping: ClientContractsTableColumnKeys;
    onChange: (req: KindergartenClientContractUpdateRequest) => void;
    onCopy: (
        req: KindergartenClientContractCopyRequest,
        tillEnd: boolean
    ) => void;
}

const Component: React.FC<KindergartenClientContractRowProps> = ({
    value,
    canEdit,
    onChange,
    onCopy,
    columnsMapping,
}) => {
    const { t } = useTranslation();
    const { formatDate, getFormattedCurrency } = useLocaleHelpers();
    const [contractNumber, setContractNumber] = useState(value.ContractNumber);
    const [resetField, setResetField] = useState(false);

    useEffect(() => {
        setResetField(true);
    }, [value.ManualCompensationAdjustment]);

    useEffect(() => {
        if (resetField) {
            setResetField(false);
        }
    }, [resetField]);

    useEffect(() => {
        setContractNumber(value.ContractNumber);
    }, [value.ContractNumber]);

    const updateContract = useCallback(
        (
            type: KindergartenClientContractType,
            uuid?: string,
            mealGroupId?: number,
            templateId?: number,
            fieldValueId?: number,
            compensationValue?: number,
            surchargeGroupUuid?: string,
            contractNumber?: string,
            salaryTemplateId?: number,
            manualCompensationAdjustment?: number
        ) => {
            const updateRequest = {
                Id: value.Id,
                ParentShareCare: value.ParentShareCare,
                ParentShareFood: value.ParentShareFood,
                MonthYear: value.MonthYear,
                MealGroupId: value.MealGroupId,
                SalaryGroupTemplateId: salaryTemplateId,
                ContractNumber: value.ContractNumber,
                SalaryGroupUuid: value.SalaryGroupUuid,
                ManualCompensationAdjustment:
                    value.ManualCompensationAdjustment,
                CareScopeUuid: value.CareScopeUuid,
                SurchargeUuid: value.SurchargeUuid,
            } as KindergartenClientContractUpdateRequest;

            if (type == ClientContractType.MealGroup) {
                updateRequest.MealGroupId = mealGroupId;
            } else if (type == ClientContractType.ContractNumber) {
                updateRequest.ContractNumber = contractNumber;
            } else if (type == ClientContractType.ManualCompensation) {
                updateRequest.ManualCompensationAdjustment =
                    manualCompensationAdjustment;
            } else {
                updateRequest.TemplateId = templateId;
                if (type == CompensationTemplateType.CareScope) {
                    updateRequest.CareScopeUuid = uuid;
                } else if (type == CompensationTemplateType.SalaryGroup) {
                    updateRequest.SalaryGroupUuid = uuid;
                } else if (type == CompensationTemplateType.Surcharge) {
                    updateRequest.SurchargeUuid = uuid;
                    updateRequest.SurchargeGroupUuid = surchargeGroupUuid;
                } else {
                    updateRequest.FieldValueId = fieldValueId;
                    if (type == CompensationTemplateType.Simple) {
                        updateRequest.Value = compensationValue;
                    }
                }
            }

            onChange(updateRequest);
        },
        [onChange, value]
    );
    const copyContracts = useCallback(
        (
            tillEnd: boolean,
            templateId?: number,
            forMealGroup?: boolean,
            forCareScope?: boolean,
            surchargeGroupUuid?: string,
            forContractNumber?: boolean,
            forManualCompensation?: boolean,
            forSalaryGroup?: boolean,
            salTemplateId?: number
        ) => {
            onCopy(
                {
                    Id: value.Id,
                    TemplateId: templateId,
                    ForMealGroup: forMealGroup,
                    ForCareScope: forCareScope,
                    ForSalaryGroup: forSalaryGroup,
                    ForManualCompensation: forManualCompensation,
                    ForContractNumber: forContractNumber,
                    SalTemplateId: salTemplateId,
                    SurchargeGroupUuid: surchargeGroupUuid,
                },
                tillEnd
            );
        },
        [onCopy, value]
    );

    return (
        <tr>
            <td style={{ paddingLeft: "10px" }}>
                {formatDate(value.MonthYear as Moment, DATEPICKER_MONTH_FORMAT)}
            </td>
            {!isNil(columnsMapping.mealGroup) &&
                (value.ColumnValues[columnsMapping.mealGroup] ||
                value.ColumnOptions[columnsMapping.mealGroup] ? (
                    <KindergartenClientContractDropdownCell
                        canEdit={canEdit}
                        value={value.ColumnValues[columnsMapping.mealGroup]}
                        options={value.ColumnOptions[columnsMapping.mealGroup]}
                        key={`${value.Id}_${columnsMapping.mealGroup}`}
                        type={ClientContractType.MealGroup}
                        copyContracts={(tillEnd) =>
                            copyContracts(tillEnd, undefined, true)
                        }
                        onChange={(val: string) => {
                            updateContract(
                                ClientContractType.MealGroup,
                                undefined,
                                !isNil(val) ? parseInt(val) : undefined
                            );
                        }}
                    />
                ) : (
                    <>
                        <td></td>
                        <td></td>
                    </>
                ))}
            {!isNil(columnsMapping.careScope) &&
                (value.ColumnValues[columnsMapping.careScope] ||
                value.ColumnOptions[columnsMapping.careScope] ? (
                    <KindergartenClientContractDropdownCell
                        canEdit={canEdit}
                        value={value.ColumnValues[columnsMapping.careScope]}
                        options={value.ColumnOptions[columnsMapping.careScope]}
                        key={`${value.Id}_${columnsMapping.careScope}`}
                        type={CompensationTemplateType.CareScope}
                        copyContracts={(tillEnd) =>
                            copyContracts(
                                tillEnd,
                                value.ColumnValues[columnsMapping.careScope]
                                    .TemplateId,
                                undefined,
                                true,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                value.ColumnValues[columnsMapping.salaryGroup] // also update salary group on care scope change if needed
                                    ? value.ColumnValues[
                                          columnsMapping.salaryGroup
                                      ].TemplateId
                                    : undefined
                            )
                        }
                        onChange={(val: string) => {
                            updateContract(
                                CompensationTemplateType.CareScope,
                                val,
                                undefined,
                                value.ColumnValues[columnsMapping.careScope]
                                    .TemplateId,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                value.ColumnValues[columnsMapping.salaryGroup]
                                    ? value.ColumnValues[
                                          columnsMapping.salaryGroup
                                      ].TemplateId
                                    : undefined
                            );
                        }}
                    />
                ) : (
                    <>
                        <td></td>
                        <td></td>
                    </>
                ))}
            {!isNil(columnsMapping.salaryGroup) &&
                (value.ColumnValues[columnsMapping.salaryGroup] ||
                value.ColumnOptions[columnsMapping.salaryGroup] ? (
                    <KindergartenClientContractDropdownCell
                        canEdit={canEdit}
                        value={value.ColumnValues[columnsMapping.salaryGroup]}
                        options={
                            value.ColumnOptions[columnsMapping.salaryGroup]
                        }
                        key={`${value.Id}_${columnsMapping.salaryGroup}`}
                        type={CompensationTemplateType.SalaryGroup}
                        copyContracts={(tillEnd) =>
                            copyContracts(
                                tillEnd,
                                value.ColumnValues[columnsMapping.salaryGroup]
                                    .TemplateId,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                true
                            )
                        }
                        onChange={(val: string) => {
                            updateContract(
                                CompensationTemplateType.SalaryGroup,
                                val,
                                undefined,
                                value.ColumnValues[columnsMapping.salaryGroup]
                                    .TemplateId
                            );
                        }}
                    />
                ) : (
                    <>
                        <td></td>
                        <td></td>
                    </>
                ))}
            {columnsMapping.surCharges.map((k) => {
                const val = value.ColumnValues[k];

                return val || value.ColumnOptions[k] ? (
                    <KindergartenClientContractDropdownCell
                        canEdit={canEdit}
                        value={val}
                        options={value.ColumnOptions[k]}
                        key={`${value.Id}_${val.TemplateId}_${val.FieldUuid}_${k}`}
                        type={CompensationTemplateType.Surcharge}
                        copyContracts={(tillEnd) =>
                            copyContracts(
                                tillEnd,
                                val.TemplateId,
                                undefined,
                                undefined,
                                val.FieldUuid
                            )
                        }
                        onChange={(newVal: string) => {
                            updateContract(
                                CompensationTemplateType.Surcharge,
                                newVal,
                                undefined,
                                val.TemplateId,
                                undefined,
                                undefined,
                                val.FieldUuid
                            );
                        }}
                    />
                ) : (
                    <React.Fragment key={`${value.Id}_${k}`}>
                        <td></td>
                        <td></td>
                    </React.Fragment>
                );
            })}
            {columnsMapping.multiOption.map((k) => {
                const val = value.ColumnValues[k];
                return val || value.ColumnOptions[k] ? (
                    <KindergartenClientContractDropdownCell
                        canEdit={canEdit}
                        value={val}
                        options={value.ColumnOptions[k]}
                        key={`${value.Id}_${val.TemplateId}_${k}`}
                        type={CompensationTemplateType.MultiOption}
                        copyContracts={(tillEnd) =>
                            copyContracts(tillEnd, val.TemplateId)
                        }
                        onChange={(newVal: string) => {
                            updateContract(
                                CompensationTemplateType.MultiOption,
                                undefined,
                                undefined,
                                val.TemplateId,
                                !isNil(newVal) ? parseInt(newVal) : undefined
                            );
                        }}
                    />
                ) : (
                    <React.Fragment key={`${value.Id}_${k}`}>
                        <td></td>
                        <td></td>
                    </React.Fragment>
                );
            })}
            {columnsMapping.singleValue.map((k) => {
                const val = value.ColumnValues[k];
                return val || value.ColumnOptions[k] ? (
                    <KindergartenClientContractSingleValueCell
                        canEdit={canEdit}
                        value={val}
                        copyContracts={(tillEnd) =>
                            copyContracts(tillEnd, val.TemplateId)
                        }
                        key={`${value.Id}_${val.TemplateId}_${k}`}
                        onChange={(newVal: number) => {
                            updateContract(
                                CompensationTemplateType.Simple,
                                undefined,
                                undefined,
                                val.TemplateId,
                                val.ValueId,
                                newVal
                            );
                        }}
                    />
                ) : (
                    <React.Fragment key={`${value.Id}__${k}`}>
                        <td></td>
                    </React.Fragment>
                );
            })}

            <td>
                <div className={styles.valueCell}>
                    <AppInputField
                        disabled={!canEdit}
                        value={contractNumber}
                        onValueChange={(val) => {
                            setContractNumber(val);
                        }}
                        style={{
                            height: "39px",
                            padding: "8px",
                            width: "185px",
                            maxWidth: "185px",
                            minHeight: "39px",
                        }}
                        onBlur={(val) => {
                            if (canEdit) {
                                if (defaultTo(val, "").length <= 20) {
                                    updateContract(
                                        ClientContractType.ContractNumber,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        val
                                    );
                                } else {
                                    showSweetAlertToast(
                                        t("common.error.error"),
                                        `${t(
                                            "kindergartenClient.contracts.externalContractError"
                                        )}`,
                                        "error"
                                    );
                                }
                            }
                        }}
                        placeholder={t(
                            "kindergartenClient.contracts.externalContract"
                        )}
                    />

                    <div className={styles.copyIcons}>
                        <AppIconButton
                            buttonType="Other"
                            showToolTip={true}
                            image={ImageAssets.common.copyBlue}
                            tooltipText={t("common.copy")}
                            readonly={!canEdit}
                            onClick={() => {
                                copyContracts(
                                    false,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    true
                                );
                            }}
                        />
                        <AppIconButton
                            buttonType="Other"
                            image={ImageAssets.common.copyFilled}
                            showToolTip={true}
                            tooltipText={t("common.copyToEnd")}
                            readonly={!canEdit}
                            onClick={() => {
                                copyContracts(
                                    true,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    true
                                );
                            }}
                        />
                    </div>
                </div>
            </td>

            {/* manual compensation field */}
            <KindergartenClientContractNumberInputCell
                canEdit={canEdit}
                value={value.ManualCompensationAdjustment}
                copyContracts={(tillEnd) =>
                    copyContracts(
                        tillEnd,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        true
                    )
                }
                style={{
                    width: "125px !important",
                    maxWidth: "125px  !important",
                }}
                placeholder={t(
                    "kindergartenClient.contracts.manualInputPlaceholder"
                )}
                onChange={(newVal: Optional<number>) => {
                    if (
                        canEdit &&
                        value.ManualCompensationAdjustment != newVal
                    ) {
                        updateContract(
                            ClientContractType.ManualCompensation,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            newVal as number
                        );
                    }
                }}
            />

            {/* placeholder cell */}
            <td></td>
            <td>{getFormattedCurrency(value.Total)}</td>
            <td>
                <div className={styles.copyIcons}>
                    <AppIconButton
                        buttonType="Other"
                        showToolTip={true}
                        readonly={!canEdit}
                        image={ImageAssets.common.copyBlue}
                        tooltipText={t("common.copy")}
                        onClick={() => {
                            copyContracts(false);
                        }}
                    />
                    <AppIconButton
                        buttonType="Other"
                        image={ImageAssets.common.copyFilled}
                        showToolTip={true}
                        readonly={!canEdit}
                        tooltipText={t("common.copyToEnd")}
                        onClick={() => {
                            copyContracts(true);
                        }}
                    />
                </div>
            </td>
        </tr>
    );
};

Component.displayName = "KindergartenClientContractRow";
export const KindergartenClientContractRow = React.memo(Component);
export default KindergartenClientContractRow;
