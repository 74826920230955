import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import { t } from "i18next";
import { BusinessType, CompanyType } from "models/business/enum";
import {
    BusinessBasicSettingsUpdateRequest,
    BusinessCreateEditRequest,
} from "models/business/request";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";
import { BusinessContractData, BusinessDetails } from "./model";

export function validateBusinessDetail(
    business: BusinessCreateEditRequest,
    state = getInitializedValidityState([], [])
) {
    const validationRules: ValidationRules<keyof BusinessCreateEditRequest> = {
        CompanyName: [
            {
                rule: Validations.REQUIRED,
                message: t("business.createEdit.companyName.missing"),
            },
        ],
        Email: [
            {
                rule: Validations.REQUIRED,
                message: t("business.createEdit.email.missing"),
            },
            {
                rule: Validations.EMAIL,
                message: t("business.createEdit.email.invalid"),
            },
        ],
        Name: [
            {
                rule: Validations.REQUIRED,
                message: t("business.createEdit.facilityName.missing"),
            },
        ],
        StreetAddress: [
            {
                rule: Validations.REQUIRED,
                message: t("business.createEdit.streetAddress.missing"),
            },
        ],
        StreetNumber: [
            {
                rule: Validations.REQUIRED,
                message: t("business.createEdit.streetNumber.missing"),
            },
        ],
        EducationalLeadership: [
            {
                rule: Validations.REQUIRED,
                message: t(
                    `business.createEdit.${business.Type == BusinessType.KINDERGARTEN
                        ? "educationalLeadership"
                        : "facilityManager"
                    }.missing`
                ),
            },
        ],
        City: [
            {
                rule: Validations.REQUIRED,
                message: t("business.createEdit.city.missing"),
            },
        ],
        PostCode: [
            {
                rule: Validations.REQUIRED,
                message: t("business.createEdit.postCode.missing"),
            },
        ],
        State: [
            {
                rule: Validations.REQUIRED,
                message: t("business.createEdit.state.missing"),
            },
        ],
        Timezone: [
            {
                rule: Validations.REQUIRED,
                message: t("business.createEdit.timezone.missing"),
            },
        ],
        ShortCompanyName: [
            {
                rule: Validations.REQUIRED,
                message: t("business.createEdit.shortCompanyName.missing"),
            },
        ],
        TotalKidsCapacity: [
            {
                rule: Validations.NUMBER,
                message: t("business.createEdit.totalKids.missing"),
            },
        ],
        DesiredKidsCapacity: [
            {
                rule: Validations.NUMBER,
                message: t("business.createEdit.desiredKids.missing"),
            },
            {
                rule: Validations.LESSER_THAN,
                message: t("business.createEdit.desiredKids.invalid"),
                options: {
                    value: business.TotalKidsCapacity,
                },
            },
        ],
        ShortInstitutionName: [
            {
                rule: Validations.REQUIRED,
                message: t("business.createEdit.shortInstitutionName.missing"),
            },
        ],
        ...(!business.BusinessRegistrationRequestId &&
            business.BranchType == CompanyType.Headquarter &&
            !business.Id
            ? {
                AdminFirstName: [
                    {
                        rule: Validations.REQUIRED,
                        message: t(
                            "business.createEdit.adminFirstName.missing"
                        ),
                    },
                ],
                AdminLastName: [
                    {
                        rule: Validations.REQUIRED,
                        message: t(
                            "business.createEdit.adminLastName.missing"
                        ),
                    },
                ],
            }
            : {}),
    };

    state = getInitializedValidityStateFromRules(
        validationRules,
        business,
        state
    );

    const manager = new ValidityStateManager(state);

    return manager.state;
}

export function validateRenewBusinessContract(
    request: BusinessContractData,
    state = getInitializedValidityState([], [])
) {
    const validationRules: ValidationRules<keyof BusinessContractData> = {
        StartDate: [
            {
                rule: Validations.REQUIRED,
                message: t("common.startDate.missing"),
            },
            {
                rule: Validations.LESSER_DATE,
                options: {
                    value: request.EndDate,
                },
            },
        ],

        EndDate: [
            {
                rule: Validations.GREATER_DATE,
                options: {
                    value: request.StartDate,
                },
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        validationRules,
        request,
        state
    );

    const manager = new ValidityStateManager(state);

    return manager.state;
}
export function validateBusinessBasicSettings(
    request: BusinessBasicSettingsUpdateRequest,
    state = getInitializedValidityState([], [])
) {
    const validationRules: ValidationRules<
        keyof BusinessBasicSettingsUpdateRequest
    > = {
        AgeGroupDeadline: [],
        TimeTrackingStart: [],
        DisableChipsAfterInactiveForDays: [
            {
                rule: Validations.NUMBER,
            },
            {
                rule: Validations.GREATER_THAN,
                options: { value: 0 },
                message: t("common.errorMessages.positiveNum"),
            },
        ],
        WorkHoursPerWeek: [
            {
                rule: Validations.NUMBER,
            },
            {
                rule: Validations.MIN,
                options: { value: 1 },
            },
        ],
        WeeksToLookForNewUserSeed: [
            {
                rule: Validations.NUMBER,
            },
            {
                rule: Validations.MIN,
                options: { value: 1 },
            },
        ],
        EmployeePreparationTime: [
            {
                rule: Validations.OPTIONAL_NUMBER,
            },
            {
                rule: Validations.GREATER_THAN,
                options: { value: 0 },
                message: t("common.errorMessages.positiveNum"),
            },
        ],
        MissingBreakStopMinutes: [
            {
                rule: Validations.NUMBER,
            },
            {
                rule: Validations.GREATER_THAN,
                options: { value: 0 },
                message: t("common.errorMessages.positiveNum"),
            },
        ],
    };

    state = getInitializedValidityStateFromRules(
        validationRules,
        request,
        state
    );

    const manager = new ValidityStateManager(state);

    return manager.state;
}
