import classNames from "classnames";
import { AppIconWithName } from "components";
import { AppTable } from "components/Table";
import { TIME_FORMAT_24H } from "globals/constants";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { getFormattedTimeFromNumber } from "globals/helpers/localizationHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { defaultTo, isNil } from "lodash-es";
import { DurationPeriodType, Optional, UserType } from "models/general";
import {
    TimeTrackingHeaderData,
    TimeTrackingRecord,
    TimeTrackingRequestParameters,
} from "models/timeManagement";
import moment from "moment";
import { Moment } from "moment-timezone";
import React from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import TimeManagementColorHighlight from "../TimeManagementColorHighlight";
import styles from "./TimeManagementPersonDetailTable.module.scss";
import TimeManagementPersonDetailTableRow from "./TimeManagementPersonDetailTableRow";

export interface TimeManagementPersonDetailTableProps {
    records: TimeTrackingRecord[];
    personId: Optional<number>;
    headerData: TimeTrackingHeaderData;
    className?: string;
    manualAllowed: boolean;
    filterRequest: TimeTrackingRequestParameters;
    onItemChange: (data: TimeTrackingRecord, idx: number) => void;
    isEmpty?: boolean;
    styleHeader?: boolean;
    refetchData?: () => void;
    date: Moment;
    emptyString?: string;
    onRowClick?: (numberId: number) => void;
}

export const TimeManagementPersonDetailTable: React.FC<
    TimeManagementPersonDetailTableProps
> = ({
    records,
    headerData,
    onItemChange,
    date,
    manualAllowed,
    personId,
    className = "",
    filterRequest,
    styleHeader = true,
    isEmpty = records.length <= 0,
    refetchData,
    emptyString,
    onRowClick,
}) => {
    const { t } = useTranslation();
    const { formatDate } = useLocaleHelpers();

    const { UserType: userType } = filterRequest;
    const headerTotal = headerData.Totals;
    const isEmployeeDayView = personId == null;

    emptyString = defaultTo(emptyString, t("common.noDataFound"));
    const showManualButton = manualAllowed;

    let maxColSpan = 7;

    if (showManualButton) {
        maxColSpan++;
    }
    if (userType != UserType.Client) {
        maxColSpan += 3;
    }

    return (
        <AppTable
            styleHeader={styleHeader}
            containerClass={className}
            hover={onRowClick != undefined}
            useMaxHeight={false}
        >
            <thead>
                <tr style={isEmployeeDayView ? { height: "0px" } : undefined}>
                    <th
                        style={{
                            minWidth: "300px",
                        }}
                    >
                        {personId && (
                            <AppIconWithName
                                className={styles.iconName}
                                text={records[0].PersonName}
                            />
                        )}
                    </th>
                    {showManualButton && (
                        <th style={getFixedCssWidths(40)}></th>
                    )}
                    <th
                        style={{
                            minWidth: "140px",
                            width: "140px",
                            maxWidth: "140px",
                        }}
                    />
                    <th
                        style={{
                            minWidth: "140px",
                            width: "140px",
                            maxWidth: "140px",
                        }}
                    />
                    {userType != UserType.Client && (
                        <th
                            style={{
                                minWidth: "150px",
                                maxWidth: "150px",
                                width: "150px",
                            }}
                            className={`text-center`}
                        >
                            {!isEmployeeDayView &&
                                getFormattedTimeFromNumber(
                                    headerTotal.BreakMinutes
                                )}
                        </th>
                    )}
                    <th
                        style={{
                            minWidth: "200px",
                            maxWidth: "200px",
                            width: "200px",
                        }}
                        className={`text-center`}
                    >
                        {!isEmployeeDayView &&
                            getFormattedTimeFromNumber(headerTotal.CareHours)}
                    </th>
                    <th
                        style={{
                            minWidth: "200px",
                            maxWidth: "200px",
                            width: "200px",
                        }}
                        className={`text-center`}
                    >
                        {!isEmployeeDayView &&
                            getFormattedTimeFromNumber(
                                headerTotal.MaxCareHours
                            )}
                    </th>
                    <th
                        style={{
                            minWidth: "200px",
                            maxWidth: "200px",
                            width: "200px",
                        }}
                        className={`text-center`}
                    >
                        {!isEmployeeDayView && (
                            <TimeManagementColorHighlight
                                differenceSumMinute={headerTotal.SumDifference}
                                isNegativeRed={userType != UserType.Client}
                            />
                        )}
                    </th>
                    {userType != UserType.Client && (
                        <>
                            <th
                                style={{
                                    minWidth: "170px",
                                    maxWidth: "170px",
                                    width: "170px",
                                }}
                            >
                                {!isEmployeeDayView && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                        className={classNames({
                                            [styles.bgRed]:
                                                !isNil(
                                                    headerTotal.ManualEntryDuration
                                                ) &&
                                                headerTotal.ManualEntryDuration <
                                                    0,
                                        })}
                                    >
                                        {getFormattedTimeFromNumber(
                                            headerTotal.ManualEntryDuration,
                                            "minute",
                                            TIME_FORMAT_24H
                                        )}
                                    </div>
                                )}
                            </th>
                            <th
                                style={{
                                    minWidth: "250px",
                                    maxWidth: "250px",
                                    width: "250px",
                                }}
                                className={styles.textGrey}
                            >
                                {!isEmployeeDayView &&
                                    headerData.DifferenceSummary && (
                                        <TimeManagementColorHighlight
                                            tooltipTitle={t(
                                                "timeManagement.differenceUptoFilteredMonth"
                                            )}
                                            differenceSumMinute={
                                                headerData.DifferenceSummary
                                                    .TimeDifferenceUptoDate
                                            }
                                            isNegativeRed={
                                                userType != UserType.Parent
                                            }
                                        />
                                    )}
                            </th>
                        </>
                    )}
                    <th
                        style={{
                            minWidth: "250px",
                            maxWidth: "250px",
                            width: "250px",
                        }}
                        className={styles.textGrey}
                    >
                        {!isEmployeeDayView &&
                        userType != UserType.Parent &&
                        headerData.DifferenceSummary ? (
                            <TimeManagementColorHighlight
                                // tooltipTitle={t(
                                //     "timeManagement.differenceForFilteredYear"
                                // )}
                                differenceSumMinute={
                                    headerData.DifferenceSummary
                                        .TimeDifferenceForYear
                                }
                                isNegativeRed={userType != UserType.Client}
                            />
                        ) : (
                            userType == UserType.Client && (
                                <TimeManagementColorHighlight
                                    tooltipTitle={t(
                                        "timeManagement.totalOverdraft"
                                    )}
                                    differenceSumMinute={
                                        headerTotal.SumDifference
                                    }
                                    isNegativeRed={userType != UserType.Client}
                                />
                            )
                        )}
                    </th>
                </tr>
            </thead>
            <tbody
                className={`${styles.tableBody} ${
                    isEmployeeDayView ? styles.fixedHeight : ""
                }`}
            >
                <tr className="dummy-row">
                    <td colSpan={maxColSpan} />
                </tr>
                <tr className="dummy-row">
                    <td colSpan={maxColSpan} />
                </tr>
                {isEmpty ? (
                    <tr>
                        <td colSpan={maxColSpan}>
                            <div
                                style={{
                                    fontSize: "1rem",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                {emptyString}
                            </div>
                        </td>
                    </tr>
                ) : (
                    records.map((x, idx) => (
                        <TimeManagementPersonDetailTableRow
                            personId={personId}
                            key={uuid()}
                            refetchData={refetchData}
                            value={x}
                            manualAllowed={showManualButton}
                            filterRequest={filterRequest}
                            preparationTime={headerData.EmployeePreparationTime}
                            onChange={(item) => onItemChange(item, idx)}
                            onRowClick={onRowClick}
                        />
                    ))
                )}
                <tr className="dummy-row">
                    <td colSpan={maxColSpan} />
                </tr>
            </tbody>
        </AppTable>
    );
};

export default TimeManagementPersonDetailTable;
