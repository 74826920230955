import {
    AgeGroupReportRecord,
    getChartColor,
} from "models/kindergartenAgeGroupReport";
import React, { useMemo } from "react";
import { Pie } from "react-chartjs-2";
import styles from "./AgeGroupMultiSeriesPieChart.module.scss";
import { DATEPICKER_MONTH_YEAR_FORMAT } from "models/datepicker";
import { shadeColor } from "models/general";
import { useTranslation } from "react-i18next";
import { isNil, reverse } from "lodash-es";
import { Chart } from "chart.js";
export interface MultiSeriesPieChartProps {
    data: AgeGroupReportRecord;
    totalKids: number;
    selectedYear: number;
}

// pass idx to stop random colors
const getColors = (existingColors: string[], idx: number) => {
    let newColor = getChartColor(idx);
    while (existingColors.includes(newColor)) {
        newColor = getChartColor(idx + 1);
        idx += 1;
    }
    return [newColor, shadeColor(newColor, 25)];
};
export const AgeGroupMultiSeriesPieChart: React.FC<
    MultiSeriesPieChartProps
> = ({ data, totalKids, selectedYear }) => {
    const { t } = useTranslation();
    const { data: processedData, levelSize } = useMemo(() => {
        // at max there can be ageGroup for SelectedYear-5 (total 6 including selected Year)
        // all age-groups will be listed in chart but in calculation we will use only 6
        const minAllowedYear = selectedYear - 5;
        const levelSize = Math.ceil(
            totalKids /
                data.AgeGroupsDetail.filter(
                    (x) => x.AgeGroupYear >= minAllowedYear
                ).length
        ); // divide total kids by number of age-groups (same proportion)

        let colorsUsed: string[] = [];
        const ageGroupSwap: { [year: number]: boolean } =
            data.AgeGroupsDetail.reduce((x, y) => {
                return {
                    ...x,
                    [y.AgeGroupYear]: false,
                };
            }, {}); // to swap label is active-kids exceed capacity
        return {
            data: {
                datasets: data.AgeGroupsDetail.map((x, idx) => {
                    const newColors = getColors(colorsUsed, idx);
                    colorsUsed = [...colorsUsed, ...newColors];
                    // if x.ActiveClients > level-size, swap position
                    ageGroupSwap[x.AgeGroupYear] = x.ActiveClients > levelSize;
                    return {
                        data: !ageGroupSwap[x.AgeGroupYear]
                            ? [x.ActiveClients, levelSize - x.ActiveClients] // correct order (active, free)
                            : [levelSize - x.ActiveClients, x.ActiveClients],
                        backgroundColor: ageGroupSwap[x.AgeGroupYear]
                            ? reverse(newColors)
                            : newColors,
                    };
                }),
                labels: data.AgeGroupsDetail.flatMap((x) => [
                    `${x.AgeGroupYear} (${t(
                        `ageGroupPlanning.${
                            ageGroupSwap[x.AgeGroupYear] ? "free" : "active"
                        }`
                    ).toString()})`,
                    `${x.AgeGroupYear} (${t(
                        `ageGroupPlanning.${
                            ageGroupSwap[x.AgeGroupYear] ? "active" : "free"
                        }`
                    ).toString()})`,
                ]),
            },
            levelSize: levelSize,
        };
    }, [data.AgeGroupsDetail, selectedYear, t]);

    return (
        <div className={styles.root}>
            <div className={styles.month}>
                <span>{data.Month.format(DATEPICKER_MONTH_YEAR_FORMAT)}</span>
            </div>
            <span style={{ textAlign: "center" }}>
                {t(`ageGroupPlanning.ageGroupCapacity`, { size: levelSize })}
            </span>
            <div className={styles.chart}>
                <Pie
                    data={processedData}
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                labels: {
                                    boxWidth: 30,
                                    generateLabels: function (chart) {
                                        // Get the default label list
                                        const original =
                                            Chart.overrides.pie.plugins.legend
                                                .labels.generateLabels;
                                        const labelsOriginal = original.call(
                                            this,
                                            chart
                                        );

                                        // Build an array of colors used in the datasets of the chart
                                        let datasetColors =
                                            chart.data.datasets.map(function (
                                                e
                                            ) {
                                                return e.backgroundColor;
                                            });
                                        datasetColors = datasetColors.flat();

                                        // Modify the color and hide state of each label
                                        labelsOriginal.forEach((label) => {
                                            if (label && !isNil(label.index)) {
                                                // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
                                                label.datasetIndex =
                                                    (label.index -
                                                        (label.index % 2)) /
                                                    2;

                                                // The hidden state must match the dataset's hidden state
                                                label.hidden =
                                                    !chart.isDatasetVisible(
                                                        label.datasetIndex
                                                    );

                                                // Change the color to match the dataset
                                                label.fillStyle = datasetColors[
                                                    label.index
                                                ] as any;
                                            }
                                        });

                                        return labelsOriginal;
                                    },
                                },
                                onClick: function (
                                    mouseEvent,
                                    legendItem,
                                    legend
                                ) {
                                    // toggle the visibility of the dataset from what it currently is
                                    legend.chart.getDatasetMeta(
                                        legendItem.datasetIndex as number
                                    ).hidden = legend.chart.isDatasetVisible(
                                        legendItem.datasetIndex as number
                                    );
                                    legend.chart.update();
                                },
                            },
                            tooltip: {
                                callbacks: {
                                    label: function (context) {
                                        const labelIndex =
                                            context.datasetIndex * 2 +
                                            context.dataIndex;
                                        return context.chart.data.labels
                                            ? context.chart.data.labels[
                                                  labelIndex
                                              ] +
                                                  ": " +
                                                  context.formattedValue
                                            : "";
                                    },
                                },
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default AgeGroupMultiSeriesPieChart;
